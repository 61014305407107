import { Field } from "@lib/shared/types";
import React, { ReactElement } from "react";

export default function Hidden({
    field,
    value,
}: {
    field: Field;
    value: string;
}): ReactElement {
    return (
        <div className="">
            <input
                name={field.codeName}
                value={value}
                id={field.codeName}
                type="hidden"
            />
        </div>
    );
}
