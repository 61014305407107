import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import React, { ReactElement } from "react";
import styles from "./styles.module.scss";

export default function SmsTCPA(): ReactElement {
    const { domain, category } = useDomainContext();
    const router = useRouter();
    const showSmsTCPA = domain.config?.showSmsTCPA && !router.query.ts;
    const linkColor = domain.config?.smsTCPALinkColor
        ? domain.config?.smsTCPALinkColor
        : "rgb(63, 127, 234)";

    return showSmsTCPA ? (
        <>
            <div className={styles["checkbox-wrapper"]}>
                <input type="checkbox" id="sms_tcpa" name="sms_tcpa" />
                <label
                    htmlFor="sms_tcpa"
                    className={`flex justify-center ${styles["checkbox-label"]} cursor-pointer mb-1`}
                >
                    <span className={styles["box"]}>
                        <svg viewBox="0,0,50,50">
                            <path d="M5 30 L 20 45 L 45 5"></path>
                        </svg>
                    </span>
                    <span>
                        By submitting your phone number, you provide express
                        written consent to receive marketing and informational
                        SMS messages from {domain.niceName} about your{" "}
                        {category?.slug === "auto-accident"
                            ? "consultation"
                            : "quote"}{" "}
                        at the phone number provided. Message frequency varies.
                        Message & data rates may apply. Text "STOP" to opt out
                        at any time. For more details, visit our{" "}
                        <a
                            target="_blank"
                            href="/privacy-policy"
                            style={{
                                color: linkColor,
                            }}
                        >
                            Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a
                            target="_blank"
                            href="/terms-and-conditions"
                            style={{
                                color: linkColor,
                            }}
                        >
                            Terms of Use
                        </a>
                        . For assistance, text "HELP" to{" "}
                        <a
                            target="_blank"
                            href="tel:+19094953188"
                            style={{
                                color: linkColor,
                            }}
                            rel="noreferrer"
                        >
                            909-495-3188
                        </a>
                    </span>
                </label>
            </div>
        </>
    ) : (
        <></>
    );
}
