import { UseFormHook } from "@hooks/useForm/types";
import { FormProps } from "..";
import { ExpandableFieldMeta, Field, FieldTypes } from "@lib/shared/types";
import { useDomainContext } from "@hooks/useDomainContext";
import { useRouter } from "next/router";
import Input from "../fields/input";
import styles from "../styles.module.scss";
import StreetAddressField from "../fields/streetAddress";
import ZipCodeExpandable from "../fields/zipCodeExpandable";
import DatePicker from "react-multi-date-picker";
import TextArea from "../fields/textarea";
import Select from "../fields/select";
import MultipleSelect from "../fields/multipleSelect";
import CalculatedYears from "../fields/calculatedYears";
import Radio from "../fields/radio";
import Range from "../fields/range";
import Checkbox from "../fields/checkbox";
import { useEffect, useMemo } from "react";
import { canShowFieldAfterDependency } from "@lib/shared/form";
import Tooltip from "rc-tooltip";
import TooltipIcon from "@components/svg/tooltipIcon";
import Hidden from "../fields/hidden";

interface FieldProps
    extends FormProps,
        Pick<
            UseFormHook,
            "handleFieldBlur" | "handleFieldChange" | "fieldsData"
        > {
    asTitle: boolean;
    field: Field;
    fields?: Field[];
    visitedSteps?: {};
    currentStep?: number;
    isLoading?: boolean;
    isLastStep?: boolean;
    formSubmitted?: boolean;
    formId?: number;
    isExpandable?: boolean;
    stepStatus: string;
    isSecondServiceForm: boolean;
    searchableMultiSelect?: boolean;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const FormField = (props: FieldProps) => {
    const {
        handleFieldBlur,
        handleFieldChange,
        onKeyDown,
        asTitle,
        fieldsData,
        field,
        fields,
        classes,
        colors,
        currentStep,
        stepStatus,
        formId,
        isExpandable,
        ignoreOptionIcons,
        isSecondServiceForm,
        searchableMultiSelect,
    } = props;

    const {
        form,
        selectedCountryCode,
        updateSelectedCountryCode,
        locale,
        domain,
    } = useDomainContext();

    const { query } = useRouter();

    const getFieldType = (): FieldTypes => {
        const metaKeys: string[] = field.meta ? Object.keys(field.meta) : [];
        // check if field has expandable meta key
        const isExpandableField =
            Array.isArray(metaKeys) && metaKeys.length
                ? metaKeys.includes("expandable")
                    ? true
                    : false
                : false;

        if (isExpandableField && field.fieldType !== "hidden") {
            const expandableFields = JSON.parse(
                field.meta?.expandable ?? "",
            ) as ExpandableFieldMeta;

            const keys = Object.keys(expandableFields);
            for (let i = 0; i < keys.length; i++) {
                if (keys[i] === field.codeName) {
                    field.fieldType = expandableFields[keys[i]]
                        ?.contentType as FieldTypes;

                    return expandableFields[keys[i]].contentType as FieldTypes;
                }
            }
            return field.fieldType;
        }
        return field.fieldType;
    };
    const getFieldErrorMessage = () => {
        if (
            selectedCountryCode === "CA" &&
            field.codeName === "zipCode" &&
            !fieldState.valid
        ) {
            return locale === "en"
                ? field.meta?.postalCodeErrorMsg ?? "Enter a Valid Postal Code"
                : field.meta?.postalCodeErrorMsgEs ??
                      "Ingrese un código postal válido";
        } else {
            return fieldState?.errorMessage;
        }
    };
    const decodeNote = (note: string | null) => {
        if (note) {
            const { meta } = field;
            if (meta && meta?.showCharCount && meta?.showCharCount === "true") {
                return note.replace(
                    /\[charCount\]/gi,
                    (
                        parseInt(field.maxValue?.value as string) -
                        fieldState.value.length
                    ).toString(),
                );
            }
            return note;
        }

        return note;
    };
    const getFieldLabel = () => {
        if (selectedCountryCode === "CA" && field.codeName === "zipCode") {
            return field.meta?.canadianLabel ?? "Postal Code";
        } else {
            return field.label;
        }
    };
    const fieldState = fieldsData[field.codeName];

    const gettingField = () => {
        const type = getFieldType();
        switch (type) {
            case "text":
            case "email":
            case "number":
            case "tel":
                return (
                    <Input
                        field={field}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        onBlur={() =>
                            !isSecondServiceForm ? handleFieldBlur(field) : null
                        }
                        value={fieldState?.value}
                        type={type}
                        className={`${classes?.fieldsClassName?.input ?? ""} ${
                            classes?.fieldsClassName?.all ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                        onKeyDown={onKeyDown}
                        fieldsData={fieldsData}
                    />
                );
            case "streetAddress":
                return (
                    <StreetAddressField
                        field={field}
                        fields={fields}
                        onChange={(field: Field, val: string) =>
                            handleFieldChange(field, val)
                        }
                        onBlur={(f: Field) =>
                            !isSecondServiceForm ? handleFieldBlur(f) : null
                        }
                        value={fieldState?.value}
                        type={type}
                        className={`${
                            classes?.fieldsClassName?.streetAddress ?? ""
                        } ${classes?.fieldsClassName?.all ?? ""} ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                        currentStep={currentStep}
                        fieldsData={fieldsData}
                        colors={colors}
                        stepStatus={stepStatus}
                        noteIndent={`${
                            (isInputField ? classes?.noteIndent : "") as string
                        }`}
                    />
                );
            case "zipCodeExpandable":
                return (
                    <ZipCodeExpandable
                        field={field}
                        fields={fields ?? []}
                        onChange={(field: Field, val: string) =>
                            handleFieldChange(field, val)
                        }
                        value={fieldState?.value}
                        type={type}
                        className={`${classes?.fieldsClassName?.all ?? ""} ${
                            classes?.fieldsClassName?.zipcodeExpandable ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                        onKeyDown={onKeyDown}
                        fieldsData={fieldsData}
                        lightBgColor={colors.lightPrimaryColor}
                    />
                );

            case "date":
                return (
                    <DatePicker
                        field={field}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        onBlur={() => handleFieldBlur(field)}
                        value={fieldState?.value}
                        className={` ${classes?.fieldsClassName?.date ?? ""} ${
                            classes?.fieldsClassName?.all ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                    />
                );

            case "textarea":
                return (
                    <TextArea
                        field={field}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        onBlur={() => handleFieldBlur(field)}
                        value={fieldState?.value}
                        className={`${
                            classes?.fieldsClassName?.textarea ?? ""
                        } ${classes?.fieldsClassName?.all ?? ""} ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                    />
                );

            case "select":
                return (
                    <Select
                        field={field}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        value={fieldState?.value}
                        className={`${classes?.fieldsClassName?.select ?? ""} ${
                            classes?.fieldsClassName?.all ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                    />
                );
            case "multiSelect":
            case "multipleSelect":
                return (
                    <MultipleSelect
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        field={field}
                        value={fieldState?.value}
                        className={`${
                            isSecondServiceForm
                                ? classes?.fieldsClassName?.checkbox ?? ""
                                : classes?.fieldsClassName?.multiSelect ?? ""
                        } ${classes?.fieldsClassName?.all ?? ""} ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                        isSecondServiceForm={isSecondServiceForm}
                        primaryColor={colors.primaryColor}
                        ignoreOptionIcons={ignoreOptionIcons}
                        searchable={searchableMultiSelect}
                        colorsConfig={{ tagColor: colors.multiSelectTagColor }}
                        classConfig={{
                            fieldErrorMessageClassName:
                                classes?.fieldErrorMessageClassName,
                            fieldNoteClassName: classes?.fieldNoteClassName,
                        }}
                        errorText={getFieldErrorMessage()}
                        noteText={decodeNote(field.note)}
                    />
                );
            case "calculatedYears":
                return (
                    <CalculatedYears
                        field={field}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        value={fieldState?.value}
                        className={`${classes?.fieldsClassName?.select ?? ""} ${
                            classes?.fieldsClassName?.all ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                    />
                );
            case "radio":
                return (
                    <Radio
                        onClick={(val: string, optionSelectedIndex: string) => {
                            handleFieldChange(field, val, optionSelectedIndex);
                        }}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        field={field}
                        value={fieldState?.value}
                        optionSelectedIndex={fieldState?.optionSelectedIndex}
                        className={`${classes?.fieldsClassName?.radio ?? ""} ${
                            classes?.fieldsClassName?.all ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                        primaryColor={colors.primaryColor}
                        ignoreOptionIcons={ignoreOptionIcons}
                        formId={formId}
                        isSecondServiceForm={isSecondServiceForm}
                    />
                );
            case "checkbox":
                return (
                    <Checkbox
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        field={field}
                        value={fieldState?.value}
                        className={`${
                            classes?.fieldsClassName?.checkbox ?? ""
                        } ${classes?.fieldsClassName?.all ?? ""} ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                        primaryColor={colors.primaryColor}
                        ignoreOptionIcons={ignoreOptionIcons}
                    />
                );
            case "range":
                return (
                    <Range
                        field={field}
                        onChange={(val: string) =>
                            handleFieldChange(field, val)
                        }
                        // onBlur={() => handleFieldBlur(field)}
                        value={fieldState?.value}
                        className={`${classes?.fieldsClassName?.range ?? ""} ${
                            classes?.fieldsClassName?.all ?? ""
                        } ${
                            isSecondServiceForm ? styles.secondServiceField : ""
                        }`}
                    />
                );
            case "hidden":
                return <Hidden field={field} value={fieldState?.value} />;
        }
    };
    useEffect(() => {
        if (
            !canShowFieldAfterDependency(field, fieldsData) &&
            fieldState.value !== ""
        )
            handleFieldChange(field, "");
    }, [fieldsData]);

    const fieldDescription = useMemo(() => {
        const fieldDesc = field.meta?.fieldDescription;
        return fieldDesc ?? null;
    }, [field]);
    const isInputField = useMemo(() => {
        const type = getFieldType();
        switch (type) {
            case "text":
            case "email":
            case "number":
            case "tel":
            case "streetAddress":
                return true;
            default:
                return false;
        }
    }, []);

    return (
        <div
            className={`${styles["form-field"]} ${
                field.conditionalLogic && field.conditionalLogic.isActive
                    ? styles["conditionalLogic"]
                    : ""
            } ${!fieldState?.valid ? styles["error"] : ""} ${
                classes?.formFieldClassName ?? ""
            } ${field.fieldType === "hidden" ? styles["hidden"] : ""}`}
            ref={(node) => {
                if (node) {
                    if (!canShowFieldAfterDependency(field, fieldsData)) {
                        node.style.setProperty("display", "none", "important");
                    } else {
                        node.style.setProperty("display", null);
                    }
                }
            }}
        >
            {field.label && !isExpandable && (
                <label
                    className={`${styles["field-label"]} ${
                        classes?.formFieldLabelClassName ?? ""
                    } ${
                        asTitle
                            ? `${styles["as-title"]} ${
                                  classes?.labelAsTitleClassName ?? ""
                              }`
                            : `${
                                  isInputField
                                      ? (classes?.roundedLabelShift as string)
                                          ? (classes?.roundedLabelShift as string)
                                          : ""
                                      : ""
                              }`
                    }`}
                >
                    <span>{getFieldLabel()}</span>

                    {field.meta?.tooltip && !isExpandable && (
                        <Tooltip
                            placement="bottom"
                            overlay={<span>{field.meta.tooltip}</span>}
                            trigger={["hover"]}
                            overlayClassName={styles["tooltip"]}
                        >
                            <div className={styles["tooltip-icon"]}>
                                <TooltipIcon />
                            </div>
                        </Tooltip>
                    )}
                    {fieldDescription && (
                        <div
                            className={`${styles["field-description"]} ${
                                classes?.formFieldDescriptionClassName ?? ""
                            }`}
                        >
                            <span>{fieldDescription}</span>
                        </div>
                    )}
                </label>
            )}

            {gettingField()}
            {!query?.countryCode &&
            selectedCountryCode &&
            field.codeName === "zipCode" &&
            form?.supportedCountries?.find(
                (item) => item.value === selectedCountryCode,
            ) ? (
                <div
                    className="text-xs mt-1 country-code-disclaimer"
                    style={{
                        color:
                            domain.config?.countryCodeColors?.textColor ??
                            "black",
                    }}
                >
                    Your current location is set to{" "}
                    {selectedCountryCode === "US" ? "United States" : "Canada"},{" "}
                    <span
                        className="text-[#15B4F9] hover:underline cursor-pointer "
                        style={{
                            color:
                                domain.config?.countryCodeColors
                                    ?.clickHereColor ?? "#15B4F9",
                        }}
                        onClick={() => {
                            if (selectedCountryCode === "CA") {
                                updateSelectedCountryCode("US");
                                return;
                            }

                            updateSelectedCountryCode("CA");
                        }}
                    >
                        Click here
                    </span>{" "}
                    to change your location to{" "}
                    {selectedCountryCode === "US" ? "Canada" : "United States"}.
                </div>
            ) : (
                <></>
            )}

            {field.fieldType !== "streetAddress" &&
                field.fieldType !== "multiSelect" &&
                field.fieldType !== "multipleSelect" && (
                    <>
                        <div
                            className={`${styles["error-message"]} ${
                                classes?.fieldErrorMessageClassName ?? ""
                            } ${
                                field.codeName === "date"
                                    ? "w-[90%] md:w-[54%]  mx-auto"
                                    : ""
                            }`}
                        >
                            <span>{getFieldErrorMessage()}</span>
                        </div>
                        {field.note && (
                            <div
                                className={`${styles["field-note"]} ${
                                    classes?.fieldNoteClassName ?? ""
                                } ${
                                    (isInputField
                                        ? classes?.noteIndent
                                        : "") as string
                                }`}
                            >
                                <span>{decodeNote(field.note)}</span>
                            </div>
                        )}
                    </>
                )}
        </div>
    );
};
